import React from 'react'

//import components
import Head from '../components/Head'
import NavBar from '../components/NavBar'
import MobileNav from '../components/MobileNav'
import Footer from '../components/Footer'
import Padding from '../components/Padding'
import Gallery from '../components/Gallery'


const headingStyles = {
    fontSize: '65px',
    color: '#912555',
    width: '80%',
    maxWidth: '1000px',
    margin: '0 auto'
}

export default () => (
    <div>
        <Head title={'404'} />
        <NavBar />
        <MobileNav />
        <Padding />
        <h1 style={headingStyles}>project not found</h1>
        <Padding />
        <Gallery />
        <Padding />
        <Footer />
    </div>
)