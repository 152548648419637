import React from 'react'
import {Link} from 'gatsby'

//import components
import Line from './Line'
import Padding from './Padding'

//import styles
import galleryStyles from './Gallery.module.css'

//import images
import mcvRedesign from '../images/home-page/mcv-redesign.jpg'
import makeMeChic from '../images/home-page/make-me-chic-email.jpg'
import socialInfographics from '../images/home-page/social-media-infographics.jpg'
import visualNarratives from '../images/home-page/visual-narratives.jpg'
import alloyChemical from '../images/home-page/alloy-chemical.jpg'
import infographic from '../images/home-page/infographic.jpg'
import comfortDecoded from '../images/home-page/comfort-decoded.jpg'
import petiteWeek from '../images/home-page/petite-week.jpg'
import instaGraphics from '../images/home-page/instagram-graphics.jpg'

export default () => {

    const projects = [
        {
            src: visualNarratives,
            alt: 'Box filled with visuals',
            title: 'Visual Narratives',
            link: 'visual-narratives'
        },
        {
            src: mcvRedesign,
            alt: 'Laptop and phone',
            title: 'MCV Web Rebrand',
            link: 'mcv-web-rebrand'
        },
        {
            src: makeMeChic,
            alt: 'Email design',
            title: 'Make Me Chic Email',
            link: 'make-me-chic-email'
        },
        {
            src: socialInfographics,
            alt: 'Holding phone',
            title: `Social Media Infographics`,
            link: 'social-media-infographics'
        },
        {
            src: instaGraphics,
            alt: 'Instagram post',
            title: 'Instagram Graphics',
            link: 'instagram-graphics'
        },
        {
            src: infographic,
            alt: 'Infographic',
            title: 'Online Shopping Infographic',
            link: 'online-shopping-infographic'
        },
        {
            src: alloyChemical,
            alt: 'Presentation slides',
            title: 'Alloy Chemical Presentation',
            link: 'alloy-chemical-presentation'
        },
        
        {
            src: comfortDecoded,
            alt: `Man's collared shirt`,
            title: `Men's Comfort Decoded Merchandise`,
            link: 'mens-comfort-decoded-merchandise'
        },
        {
            src: petiteWeek,
            alt: 'Posters',
            title: 'Petite Week Merchandise',
            link: 'petite-week-merchandise'
        }    
    ]

    return(
    <>
        <div className={galleryStyles.content}>
            <Line color={'black'} />
            <h2>MY WORK</h2>
        </div>
        <Padding size='s' />
        <div className={galleryStyles.gallery}>
            {projects.map((project, index) =>
                <Link
                    to={`/${project.link}`}
                    key={index}
                > 
                    <div className={galleryStyles.container}>
                        <picture>
                            <source media='(max-width: 500px)' srcSet={project.mobile} />
                            <img src={project.src} alt={project.alt} />
                        </picture>
                        <div className={galleryStyles.overlay}>
                            <div className={galleryStyles.overlayText}>{project.title}</div>
                        </div>
                    </div>
                </Link>
            )}    
        </div>
    </>
    )
}